// Selectors
const drops = document.querySelectorAll(
  ".navbar-nav .dropdown, .navbar-nav .dropend"
);

// Events
const showEvents = [];
const hideEvents = ["mouseleave", "focusout"];

// Transition
const transitionDuration = 200;

// Breakpoint
const desktopSize = 992;

// Show drop
function showDrop(menu) {
  if (!menu) return;
  if (window.innerWidth < desktopSize) {
    return;
  }

  menu.classList.add("showing");

  setTimeout(function () {
    menu.classList.remove("showing");
    menu.classList.add("show");
  }, 1);
}

// Hide drop
function hideDrop(e, menu, dropdown) {
  if (!menu) return;

  // remove focus
  var navLink = dropdown.querySelector(".nav-link");
  if (navLink && navLink.classList.contains("show")) {
    // Remove the 'show' class
    navLink.classList.remove("show");
  }

  setTimeout(function () {
    if (window.innerWidth < desktopSize) {
      return;
    }

    if (!menu.classList.contains("show")) {
      return;
    }

    if (e.type === "click" && e.target.closest(".dropdown-menu form")) {
      return;
    }

    menu.classList.add("showing");
    menu.classList.remove("show");

    setTimeout(function () {
      menu.classList.remove("showing");
    }, transitionDuration);
  }, 2);
}

drops.forEach(function (dropdown) {
  const menu = dropdown.querySelector(".dropdown-menu");

  // Show drop
  showEvents.forEach(function (event) {
    dropdown.addEventListener(event, function () {
      showDrop(menu);
    });
  });

  // Hide drop
  hideEvents.forEach(function (event) {
    dropdown.addEventListener(event, function (e) {
      hideDrop(e, menu, dropdown);
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const aboutDropdown = document.getElementById("navbarAboutUsContainer");
  const careerDropdown = document.getElementById("career-dropdown");
  const careerLink = careerDropdown.querySelector(".dropdown-link");
  const careerSubMenu = document.getElementById("about-sub-menu-career");
  careerLink.addEventListener("click", function (event) {
    event.stopPropagation();
    careerSubMenu.classList.toggle("show");
  });

  careerSubMenu.addEventListener("mouseleave", function (event) {
    event.stopPropagation();
    careerSubMenu.classList.toggle("show");
  });

  aboutDropdown.addEventListener("click", function (event) {
    if (careerSubMenu.classList.contains("show")) {
      careerSubMenu.classList.remove("show");
    }
  });
});
