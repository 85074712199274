import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

document.addEventListener("DOMContentLoaded", () => {
  const carouselContainer = document.querySelector(".home-carousel__container");

  if (carouselContainer) {
    const swiper = new Swiper(".home-carousel__container", {
      modules: [Navigation, Pagination, Autoplay],

      // Optional parameters
      loop: true,
      slidesPerView: 1,
      slideToClickedSlide: true,
      allowTouchMove: true,
      spaceBetween: 10,
      grabCursor: true,

      breakpoints: {
        767: {
          slidesPerView: 2,
          touchRatio: 0,
          grabCursor: false,
        },
        1020: {
          slidesPerView: 3,
          width: 250,
          touchRatio: 0,
          grabCursor: false,
        },
      },

      speed: 500,

      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
      },

      // If we need pagination
      pagination: {
        el: ".primary-slider-pagination",
        // dynamicBullets: true,
        type: "bullets",
        clickable: true,
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    const prevBtn = document.querySelector(
      ".home-carousel__container .swiper-button-prev"
    );
    const nxtBtn = document.querySelector(
      ".home-carousel__container .swiper-button-next"
    );

    if (prevBtn && nxtBtn) {
      const handleScreenSizeChange = () => {
        if (window.innerWidth > 767) {
          prevBtn.classList.add("swiper-button__show");
          nxtBtn.classList.add("swiper-button__show");
        } else {
          prevBtn.classList.remove("swiper-button__show");
          nxtBtn.classList.remove("swiper-button__show");
        }
      };

      handleScreenSizeChange();

      carouselContainer.addEventListener("mouseout", () => {
          prevBtn.classList.remove("swiper-button__show");
          nxtBtn.classList.remove("swiper-button__show");
      });

      carouselContainer.addEventListener("mouseover", () => {
          if (window.innerWidth > 767) {
          prevBtn.classList.add("swiper-button__show");
          nxtBtn.classList.add("swiper-button__show");
        }
      });

      window.addEventListener("resize", handleScreenSizeChange);
    }
  }
});

