//Initialize the modal when the page gets loaded.

const modal = document.getElementById('ITSAmodal');

if (modal){
    document.addEventListener('DOMContentLoaded', () => {
        const modalInstance = new window.Modal(modal, {  //Modal is globally exported
            backdrop: true,
        })
        
        modalInstance.show();
    })
}